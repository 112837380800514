<template>
  <div class="table" v-if="value.data">
    <!-- <processing-modal :active="processing" v-on:cancel="closeModal(2)" /> -->

    <div class="table-header">
      <h3>{{ value.title }}</h3>
      <button class="download-button" v-on:click="downloadCSV">
        Download Detailed CSV
        <font-awesome-icon class="icon" icon="file" />
      </button>
    </div>
    <h4 v-if="value.subtitle">Note: {{ value.subtitle }}</h4>

    <div class="table-wrapper">
      <div class="table-row header">
        <div class="search-input">
          <input
            type="text"
            placeholder="Search..."
            v-on:input="onSearch($event.target.value)"
          />
          <!-- Temp solution for including switch -->
        </div>
        <div class="right-side">
          <diyobo-switch
            :label="'Show Expected'"
            :value="guestlist"
            v-if="showExpected"
            @change="changeSwitch"
          />
          <div class="entries-input">
            <p>Show</p>
            <div class="entries-dropdown">
              <diyobo-input
                type="dropdown-no-label-small"
                :placeholder="tableSize.label"
                :value="tableSize.value"
                :options="tableSizes"
                v-on:input="onChangeTableSize"
              />
            </div>
            <p>entries</p>
          </div>
        </div>
      </div>
      <div class="table-row sub-header" v-if="dateSearch">
        <!-- TODO: Calendar needs to be replaced with vue formulate calendar -->
        <!-- <ClientOnly>
          <div class="calendar-wrapper">
            <diyobo-calendar
              :label="dateFrom"
              :noStars="true"
              :pickerId="'startDate' + datepickerIdSuffix"
              :inputId="'startDateInput' + datepickerIdSuffix"
              :val="start"
              v-model="start"
              v-on:dateSet="setStart"
            ></diyobo-calendar>
          </div>
          <div class="calendar-wrapper">
            <diyobo-calendar
              :label="dateTo"
              :noStars="true"
              :pickerId="'endDate' + datepickerIdSuffix"
              :inputId="'endDateInput' + datepickerIdSuffix"
              :val="end"
              v-model="end"
              v-on:dateSet="setEnd"
            ></diyobo-calendar>
          </div>
        </ClientOnly> -->
      </div>
      <div class="table-row guestlist-header" v-if="guestlist">
        <div
          v-for="(column, index) in columns"
          class="th-element"
          :class="{ center: column.centerTitle }"
          :key="index"
          v-on:click="onToggleSort(column)"
        >
          <font-awesome-icon
            v-if="column.prefixIcon"
            class="prefix-icon"
            :icon="column.prefixIcon"
          ></font-awesome-icon>
          {{ column.title }}
          <span v-if="sortColumn === column.field" class="sort-icon">
            <font-awesome-icon
              v-if="sortDirection === 'ascending'"
              icon="chevron-up"
            />
            <font-awesome-icon
              v-else-if="sortDirection === 'descending'"
              icon="chevron-down"
            />
          </span>
        </div>
      </div>
      <div class="table-row" v-else>
        <div
          v-for="(column, index) in columns"
          class="th-element"
          :class="{ center: column.centerTitle }"
          :key="index"
          v-on:click="onToggleSort(column)"
          :style="{ 'flex-basis': column.width, width: column.width }"
        >
          <font-awesome-icon
            v-if="column.prefixIcon"
            class="prefix-icon"
            :icon="column.prefixIcon"
          ></font-awesome-icon>
          {{ column.title }}
          <span v-if="sortColumn === column.field" class="sort-icon">
            <font-awesome-icon
              v-if="sortDirection === 'ascending'"
              icon="chevron-up"
            />
            <font-awesome-icon
              v-else-if="sortDirection === 'descending'"
              icon="chevron-down"
            />
          </span>
        </div>
      </div>
      <div class="table-row-wrapper" v-if="guestlist">
        <div
          class="table-row-guestlist table-row"
          v-for="(row, rowIndex) in displayData"
          :key="rowIndex"
        >
          <div class="row-1">
            <div
              v-for="(column, index) in columns"
              class="td-element"
              :class="{
                'check-btn': column.checkBtn,
                buttons: column.guestlistBtn,
                dropdown: column.dropdown,
              }"
              :key="index"
            >
              <span
                v-if="column.isCurrency"
                :class="{ indent: column.input || column.checkbox }"
                >$</span
              >
              <input
                v-if="column.input && column.isCurrency"
                class="edit-input-big"
                type="text"
                v-model="row[column.field]"
                v-on:keydown="handleMoneyKeydown($event, row, column.field)"
                v-on:blur="onBlurMoney(row, column.field)"
              />
              <input
                v-else-if="column.input && column.email"
                class="edit-input"
                type="text"
                :class="{ error: row._errors && row._errors[column.field] }"
                v-model="row[column.field]"
                v-on:input="handleEmailInput(row, column.field)"
              />
              <input
                v-else-if="column.input && column.phone"
                class="edit-input"
                type="text"
                :class="{ error: row._errors && row._errors[column.field] }"
                v-model="row[column.field]"
                v-on:input="handlePhoneInput(row, column.field)"
              />
              <input
                v-else-if="column.input"
                class="edit-input"
                type="text"
                :class="{ error: row._errors && row._errors[column.field] }"
                v-model="row[column.field]"
                v-on:input="handleInput(row, column.field)"
              />

              <input
                v-else-if="column.checkbox"
                class="edit-input"
                type="checkbox"
                v-model="row[column.field]"
                v-on:change="handleInput(row, column.field)"
              />

              <!-- <div v-else-if="column.dropdown">
                {{ row[column.field] }}
              </div> -->
              <diyobo-input
                v-else-if="column.dropdown"
                noLabel
                placeholder="Select"
                type="dropdown"
                v-on:change="handleInput(row, column.field)"
                v-model="row[column.field]"
                :val="row[column.field] || ''"
                :options="column.options"
              />
              <!-- <span v-else-if="column.dropdown && column.status">
                <diyobo-input
                  noLabel
                  placeholder="Select"
                  v-if="row['status'] === 'waiting'"
                  type="dropdown"
                  v-on:change="handleInput(row, column.field)"
                  v-model="row[column.field]"
                  :val="row[column.field] || ''"
                  :options="column.options"
                />
                <span
                  v-else
                  class="status-span"
                  :class="{ center: column.center }"
                  >{{ row["status"] }}</span
                >
              </span> -->
              <!-- <span v-else-if="column.dropdown && column.status">
                <diyobo-input
                  v-if="row['status'] === 'waiting'"
                  noLabel
                  placeholder=""
                  type="dropdown"
                  v-on:change="handleInput(row, column.field)"
                  v-model="row[column.field]"
                  :val="row[column.field]"
                  :options="column.options"
                />
                <span
                  v-else
                  class="status-span"
                  :class="{ center: column.center }"
                  >{{ row["status"] }}</span
                >
              </span> -->
              <div v-else-if="column.yesNo" class="yes-no">
                <input
                  class="yes-no-check-input"
                  type="checkbox"
                  ref="yesNo"
                  v-model="row[column.field]"
                  v-on:change="handleInput(row, column.field)"
                />
                <span class="yes-no-check-text"> </span>
              </div>

              <span v-else-if="column.bool">
                <font-awesome-icon
                  v-if="row[column.field]"
                  icon="check"
                  class="icon"
                />
                <font-awesome-icon v-else icon="times" class="icon" />
              </span>
              <span
                v-else-if="column.icon"
                v-on:click="handleInput(row, column.field)"
              >
                <font-awesome-icon
                  :icon="getIcon(row, column.field)"
                  class="icon clickable"
                />
              </span>
              <span v-else-if="column.isCurrency">
                {{ formatMoney(row[column.field]) }}
              </span>
              <span v-else-if="column.link && getUrl(row)">
                <router-link :to="getUrl(row)">
                  {{ row[column.field] }}
                  <font-awesome-icon icon="external-link-alt" />
                </router-link>
              </span>
              <span v-else-if="column.modal">
                <span
                  class="modal-btn"
                  :class="{ center: column.center }"
                  v-on:click="$emit('modal', row, rowIndex)"
                >
                  {{ column.modalTitle }}
                </span>
              </span>

              <!-- for guestlist -->

              <span class="check-btn" v-else-if="column.checkBtn">
                <font-awesome-icon
                  icon="check"
                  class="icon"
                  @click="$emit('save', row)"
                />
                <!-- <diyobo-button
                    txt="Approve"
                    class="approve"
                    @click="approveGuest(row, column.field)"
                  ></diyobo-button>
                  <diyobo-button
                    txt="Deny"
                    class="deny"
                    @click="denyGuest(row, column.field)"
                  ></diyobo-button> -->
              </span>

              <span class="buttons" v-else-if="column.guestlistBtn">
                <span v-if="row[column.field] !== 'waiting'">
                  <input
                    class="edit-input"
                    type="checkbox"
                    :checked="row['checkedIn']"
                  />
                </span>
                <span
                  v-else-if="row[column.field] === 'waiting'"
                  class="buttons"
                >
                  <diyobo-button
                    txt="Approve"
                    class="approve"
                    @click="approveGuest(row, column.field)"
                  ></diyobo-button>
                  <diyobo-button
                    txt="Deny"
                    class="deny"
                    @click="denyGuest(row, column.field)"
                  ></diyobo-button>
                </span>
              </span>

              <span
                class="spinner-input"
                v-else-if="column.spinner && column.male"
              >
                <div class="male-counter">
                  <div class="counter">
                    <span
                      class="minus"
                      @click="minus('male', row, column.field)"
                      >-</span
                    >
                    <input
                      type="number"
                      name=""
                      id=""
                      class="male-input"
                      min="1"
                      disabled
                      v-model="row[column.field]"
                    />
                    <span class="plus" @click="plus('male', row, column.field)"
                      >+</span
                    >
                  </div>
                </div>
              </span>
              <span
                class="spinner-input"
                v-else-if="column.spinner && column.female"
              >
                <div class="male-counter">
                  <div class="counter">
                    <span
                      class="minus"
                      @click="minus('female', row, column.field)"
                      >-</span
                    >
                    <input
                      type="number"
                      name=""
                      id=""
                      disabled
                      class="male-input"
                      min="1"
                      v-model="row[column.field]"
                    />
                    <span
                      class="plus"
                      @click="plus('female', row, column.field)"
                      >+</span
                    >
                  </div>
                </div>
              </span>
              <span
                class="spinner-input"
                v-else-if="column.spinner && column.ns"
              >
                <div class="male-counter">
                  <div class="counter">
                    <span class="minus" @click="minus('ns', row, column.field)"
                      >-</span
                    >
                    <input
                      type="number"
                      name=""
                      id=""
                      disabled
                      class="male-input"
                      min="1"
                      v-model="row[column.field]"
                    />
                    <span class="plus" @click="plus('ns', row, column.field)"
                      >+</span
                    >
                  </div>
                </div>
              </span>

              <span
                class="guest-total"
                v-else-if="column.guesttotal"
                :class="[
                  { checkin: row[column.field] > 0 },
                  { same: row['exp_guests'] == row[column.field] },
                  { over: row[column.field] > row['exp_guests'] },
                ]"
              >
                {{ row[column.field] }}
              </span>

              <span v-else :class="{ center: column.center }">{{
                row[column.field]
              }}</span>
            </div>
          </div>
          <div class="row-2">
            <div
              class="td-wrapper"
              v-for="(column, index) in value.columns"
              :key="index"
              :style="{
                display: column.row2 ? 'initial' : 'none',
              }"
            >
              <div v-if="column.row2 && column.exp" class="td-element">
                <span :class="{ center: column.center }">{{
                  row[column.field]
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="table-row"
        v-for="(row, rowIndex) in displayData"
        :key="rowIndex"
      >
        <div
          v-for="(column, index) in columns"
          class="td-element"
          :class="{
            'check-btn': column.checkBtn,
            buttons: column.guestlistBtn,
            dropdown: column.dropdown,
          }"
          :key="index"
          :style="{ 'flex-basis': column.width, width: column.width }"
        >
          <div v-if="column.disabled" class="mask"></div>
          <input
            v-if="column.input && column.isCurrency"
            class="edit-input-big"
            type="text"
            v-model="row[column.field]"
            v-on:keydown="handleMoneyKeydown($event, row, column.field)"
            v-on:blur="onBlurMoney(row, column.field)"
          />
          <input
            v-else-if="column.input && column.email"
            class="edit-input"
            type="text"
            :class="{ error: row._errors && row._errors[column.field] }"
            v-model="row[column.field]"
            v-on:input="handleEmailInput(row, column.field)"
          />
          <input
            v-else-if="column.input && column.phone"
            class="edit-input"
            type="text"
            :class="{ error: row._errors && row._errors[column.field] }"
            v-model="row[column.field]"
            v-on:input="handlePhoneInput(row, column.field)"
          />
          <input
            v-else-if="column.input"
            class="edit-input"
            type="text"
            :class="{
              error: row._errors && row._errors[column.field],
              center: column.center,
            }"
            v-model="row[column.field]"
            v-on:input="handleInput(row, column.field)"
          />
          <FormulateInput
            class="fInput"
            v-else-if="column.fInput"
            type="text"
            v-model="row[column.field]"
            @input="handleInput(row, column.field)"
          />
          <FormulateInput
            class="fInput"
            v-else-if="column.fInputEmail"
            type="email"
            validation="email"
            v-model="row[column.field]"
            @input="handleInput(row, column.field)"
          />
          <FormulateInput
            class="fInput"
            v-else-if="column.fInputPhone"
            type="tel"
            v-model="row[column.field]"
            @input="handleInput(row, column.field)"
          />
          <FormulateInput
            class="fInput"
            v-else-if="column.fInputNumber"
            type="number"
            validation="number"
            v-model="row[column.field]"
            @input="handleInput(row, column.field)"
          />
          <div v-else-if="column.slot">
            <slot
              name="buttons"
              :row="row"
              :column="column"
              :data="row[column.field]"
            ></slot>
          </div>
          <input
            v-else-if="column.checkbox"
            class="edit-input"
            type="checkbox"
            v-model="row[column.field]"
            v-on:change="handleInput(row, column.field)"
          />
          <diyobo-input
            v-else-if="column.dropdown && !column.status"
            noLabel
            placeholder="Select"
            type="dropdown"
            v-on:change="handleInput(row, column.field)"
            v-model="row[column.field]"
            :val="row[column.field] || ''"
            :options="column.options"
          />
          <span v-else-if="column.dropdown && column.status">
            <diyobo-input
              noLabel
              placeholder="Select"
              v-if="row['status'] === 'waiting'"
              type="dropdown"
              v-on:change="handleInput(row, column.field)"
              v-model="row[column.field]"
              :val="row[column.field] || ''"
              :options="column.options"
            />
            <span
              v-else
              class="status-span"
              :class="{ center: column.center }"
              >{{ row["status"] }}</span
            >
          </span>

          <div v-else-if="column.yesNo" class="yes-no">
            <input
              class="yes-no-check-input"
              type="checkbox"
              ref="yesNo"
              v-model="row[column.field]"
              v-on:change="handleInput(row, column.field)"
            />
            <span class="yes-no-check-text"> </span>
          </div>

          <span v-else-if="column.bool">
            <font-awesome-icon
              v-if="row[column.field]"
              icon="check"
              class="icon"
            />
            <font-awesome-icon v-else icon="times" class="icon" />
          </span>
          <span
            class="icon-container"
            v-else-if="column.icon"
            v-on:click="handleInput(row, column.field)"
          >
            <font-awesome-icon
              :icon="getIcon(row, column.field)"
              class="icon clickable"
            />
          </span>
          <span v-else-if="column.isPromoDiscount">
            {{ promoDiscountDisplay(row, column.field) }}
          </span>
          <span v-else-if="column.isBulkDiscount">
            {{ bulkDiscountDisplay(row, column.field) }}
          </span>
          <span v-else-if="column.isCurrency">
            {{ formatMoney(row[column.field]) }}
          </span>
          <span v-else-if="column.link && getUrl(row)">
            <router-link :to="getUrl(row)">
              {{ row[column.field] }}
              <font-awesome-icon icon="external-link-alt" />
            </router-link>
          </span>
          <span v-else-if="column.modal" :class="{ center: column.center }">
            <span
              class="modal-btn"
              :class="{ center: column.center }"
              v-on:click="$emit('modal', row, rowIndex)"
            >
              {{ column.modalTitle }}
            </span>
          </span>

          <!-- for buyerlist -->
          <span v-else-if="column.modalTwo" :class="{ center: column.center }">
            <span
              class="modal-btn"
              :class="{ center: column.center }"
              v-on:click="$emit('modalTwo', row, rowIndex)"
            >
              {{ column.modalTwoTitle }}
            </span>
          </span>
          <!-- for guestlist -->
          <span class="spinner-input" v-else-if="column.spinner && column.male">
            <div class="male-counter">
              <!-- <div class="gender-icon male">
                <font-awesome-icon :icon="['fas', 'mars']" class="male-icon" />
              </div> -->
              <div class="counter">
                <span class="minus" @click="minus('male', row, column.field)"
                  >-</span
                >
                <input
                  type="number"
                  name=""
                  id=""
                  class="male-input"
                  min="1"
                  disabled
                  v-model="row[column.field]"
                />
                <span class="plus" @click="plus('male', row, column.field)"
                  >+</span
                >
              </div>
            </div>
          </span>
          <span
            class="spinner-input"
            v-else-if="column.spinner && column.female"
          >
            <div class="male-counter">
              <!-- <div class="gender-icon male">
                <font-awesome-icon :icon="['fas', 'mars']" class="male-icon" />
              </div> -->
              <div class="counter">
                <span class="minus" @click="minus('female', row, column.field)"
                  >-</span
                >
                <input
                  type="number"
                  name=""
                  id=""
                  disabled
                  class="male-input"
                  min="1"
                  v-model="row[column.field]"
                />
                <span class="plus" @click="plus('female', row, column.field)"
                  >+</span
                >
              </div>
            </div>
          </span>
          <span class="spinner-input" v-else-if="column.spinner && column.ns">
            <div class="male-counter">
              <!-- <div class="gender-icon male">
                <font-awesome-icon :icon="['fas', 'mars']" class="male-icon" />
              </div> -->
              <div class="counter">
                <span class="minus" @click="minus('ns', row, column.field)"
                  >-</span
                >
                <input
                  type="number"
                  name=""
                  id=""
                  disabled
                  class="male-input"
                  min="1"
                  v-model="row[column.field]"
                />
                <span class="plus" @click="plus('ns', row, column.field)"
                  >+</span
                >
              </div>
            </div>
          </span>

          <span class="check-btn" v-else-if="column.checkBtn">
            <font-awesome-icon
              icon="check"
              class="icon"
              @click="$emit('save', row)"
            />
            <!-- <diyobo-button
                    txt="Approve"
                    class="approve"
                    @click="approveGuest(row, column.field)"
                  ></diyobo-button>
                  <diyobo-button
                    txt="Deny"
                    class="deny"
                    @click="denyGuest(row, column.field)"
                  ></diyobo-button> -->
          </span>
          <span class="buttons" v-else-if="column.guestlistBtn">
            <!-- && getStatus(row[column.field]) -->

            <span v-if="row[column.field] !== 'waiting'">
              <input
                class="edit-input"
                type="checkbox"
                :checked="row['checkedIn']"
              />
            </span>
            <span v-else-if="row[column.field] === 'waiting'" class="buttons">
              <diyobo-button
                txt="Approve"
                class="approve"
                @click="approveGuest(row, column.field)"
              ></diyobo-button>
              <diyobo-button
                txt="Deny"
                class="deny"
                @click="denyGuest(row, column.field)"
              ></diyobo-button>
            </span>
          </span>

          <span
            class="guest-total"
            v-else-if="column.guesttotal"
            :class="[
              { checkin: row[column.field] > 0 },
              { same: row['exp_guests'] == row[column.field] },
              { over: row[column.field] > row['exp_guests'] },
            ]"
          >
            {{ row[column.field] }}
          </span>
          <!-- <span v-else-if="column.field === 'active'"> checkbox </span> -->

          <!-- <span class="buttons"> </span> -->

          <span v-else :class="{ center: column.center }">{{
            row[column.field]
          }}</span>
        </div>
      </div>

      <div class="table-row" v-if="!displayData.length">
        <div class="td-element" style="flex-basis: 100%">
          There is no data for this table to display.
        </div>
      </div>
      <div class="table-row" v-if="value.totals">
        <div
          v-for="(column, index) in columns"
          class="td-element"
          :key="index"
          :style="{ 'flex-basis': column.width, width: column.width }"
        >
          <span
            v-if="
              (index === 0 && !column.icon) || (index == 1 && columns[0].icon)
            "
          >
            <strong>Totals</strong>
          </span>
          <span v-else-if="value.totals[column.field]">
            <span v-if="column.isCurrency">
              {{ formatMoney(value.totals[column.field](value.data)) }}
            </span>
            <span v-if="!column.isCurrency">
              {{ value.totals[column.field](value.data) }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="table-footer">
      <div class="entries-wrapper" v-if="value.data.length > 0">
        <p>
          Showing {{ showingFrom }} to {{ showingTo }} of
          {{ filteredData.length }}
          <span v-if="value.data.length === 1">order</span>
          <span v-else>orders</span>.
        </p>
      </div>
      <div class="pagination" v-if="pageButtons.length > 1">
        <p
          class="from"
          :class="{ inactive: page <= 1 }"
          v-on:click="paginate(-1, true)"
        >
          <font-awesome-icon icon="chevron-left" />
        </p>
        <p
          v-for="_page in pageButtons"
          :key="_page"
          :class="{ active: _page === page }"
          v-on:click="paginate(_page)"
        >
          {{ _page }}
        </p>
        <p
          class="to"
          :class="{ inactive: page >= pages }"
          v-on:click="paginate(1, true)"
        >
          <font-awesome-icon icon="chevron-right" />
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.table {
  .input-wrapper {
    .dropdown {
      padding: 4px !important;

      .icon-down {
        right: 7px;
        top: 6px;
      }
    }
    .option {
      padding: 4px !important;
    }
  }
}
</style>
<style lang="less" scoped>
.fInput {
  margin-top: 1rem;
}
.table {
  .table-header {
    display: flex;
    justify-content: space-between;

    .download-button {
      height: 40px;
      margin: auto;
      margin-right: 0;
      background: transparent;
      border: none;
      color: var(--primary);
      font-weight: bold;
      cursor: pointer;

      .icon {
        margin-left: 5px;
      }
    }
  }

  .table-wrapper {
    position: relative;
    background-color: var(--table-bg);
    // border: solid 1px black;
    border-radius: 8px;
    box-shadow: var(--table-shadow);
    width: 100%;
    overflow: auto;
    min-height: 230px;
    padding-bottom: 60px;

    .sub-header {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .calendar-wrapper {
        width: 260px;
        margin-right: 20px;
        margin-top: 20px;
      }
    }

    .table-row {
      position: relative;
      display: flex;
      padding: 0px 10px 0px 10px;

      &.guestlist-header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr;
      }
      &:not(.header, .sub-header) {
        // overflow: auto;
        min-width: 1000px;
      }

      .mask {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(82, 82, 82, 0.5);
      }

      .modal-btn {
        cursor: pointer;
      }

      .th-element {
        display: flex;
        justify-content: left;
        text-align: left;
        color: var(--text);
        font-size: 12px;
        // margin: 5px 0px;
        padding: 10px 5px 10px 5px;
        border-bottom: #4a4c52 solid 1px;
        user-select: none;
        align-items: center;
        cursor: pointer;

        &.center {
          justify-content: center;
        }

        .sort-icon {
          margin-left: 5px;
        }

        .prefix-icon {
          margin-right: 5px;
        }
      }

      .td-element {
        display: flex;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        // height: 18px;
        justify-content: left;
        align-items: center;
        font-size: 12px;
        color: var(--text);
        padding: 10px 5px;
        border-bottom: var(--input-border) solid 1px;
        //  rgba(91, 91, 91, 0.3) solid 1px;

        &.buttons {
          padding: 0;
          height: 38px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &.check-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;

          span.check-btn {
            width: 30px;
            height: 30px;
            // background: green;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-radius: 50%;
            cursor: pointer;
          }
        }

        .status-span {
          text-transform: capitalize;
        }

        &.dropdown {
          overflow: initial;
        }

        span.buttons {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          button {
            padding: 5px 10px !important;
          }
        }

        span.spinner-input {
          width: 100%;

          div.counter {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .minus,
            .plus {
              font-size: 13px;
              background: var(--dropdown-background);
              color: var(--text);
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              border-radius: 14px;
              border: 1px solid var(--dropdown-border);
              font-weight: bold;
              cursor: pointer;
              user-select: none;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            input[type="number"] {
              -moz-appearance: textfield; /* Firefox */
            }
            input {
              width: 40px;
              text-align: center;
              box-shadow: 1px 1px 2px -1px #ffffff26,
                inset 1px 1px 3px 0px #00000026;
              border: 1px solid transparent;
              background: var(--input-background);
              color: var(--text);
              border-radius: 4px;
              display: inline-block;
              vertical-align: middle;
              -webkit-appearance: none;
              outline: none;

              &:hover {
                border: 1px solid var(--input-border);
              }
              &:focus {
                color: var(--text);
                border: 1px solid #48bb8b;
                background-color: var(--active-background);
              }
            }
          }
        }
        span.guest-total {
          width: 100%;
          display: block;
          font-weight: bold;
          font-size: 16px;
          text-align: center;
          border-radius: 10px;
          color: white;
          &.checkin {
            background: #449a4c;
          }
          &.same {
            background: #2bacd9;
          }
          &.over {
            background: red;
          }
        }
        span.center {
          text-align: center;
          width: 100%;
          display: block;
        }
        span {
          margin-top: -1px;

          &.indent {
            margin-right: 5px;
          }
        }

        a {
          color: @colors[text];

          a:visited {
            color: @colors[text];
          }

          .fa-external-link-alt {
            margin-left: 10px;
          }
        }

        input[type="text"] {
          width: 90%;
          font-family: inherit;
          font-size: 0.95em;
          padding: 0 5px;
          background: var(--input-background);
          color: var(--text);
          border: none;
          // border-bottom: 1px solid #3b3b3b;
          border-radius: 5px;
          box-shadow: 1px 1px 2px -1px #ffffff26,
            inset 1px 1px 3px 0px #00000026;
          // box-shadow: 1px 1px 2px -1px #ffffff26,
          //   inset 1px 1px 2px 0px #00000075;
          outline: none;
          -webkit-appearance: none;
          transition: 0.4s;

          &.error {
            background-color: @colors[input-error];

            &:focus {
              background-color: @colors[input-error-focus];
            }
          }
        }

        input[type="checkbox"] {
          width: 15px;
          height: 15px;
          font-size: 1em;
          background-color: #181818;
          border: 0.2em solid #0f0f0f;
          border-radius: 5px;
          outline-color: #0f0f0f;
          margin-right: 8px;
          vertical-align: bottom;
          box-shadow: none;
          margin-left: 0;
          margin-top: 2px;
          appearance: none;
          -webkit-appearance: none;
          background: radial-gradient(#e60022, #0f0f0f);
          outline: none;
          cursor: pointer;

          &:checked {
            background: radial-gradient(#4cac55, #0f0f0f);
          }
        }
        div.yes-no {
          display: flex;
          align-items: center;

          .yes-no-check-text:before {
            content: "N";
          }

          .yes-no-check-input:checked ~ .yes-no-check-text:before {
            content: "Y";
          }
        }

        // div.yes-no {
        //   .yes-no-check-text:before {
        //     content: "No";
        //   }

        //   .yes-no-check-input:checked ~ .yes-no-check-text:before {
        //     content: "Yes";
        //   }
        // }
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          cursor: pointer;
          .icon {
            width: 12px;
            height: 12px;

            &.clickable {
              cursor: pointer;
            }
          }
        }
      }

      .right-side {
        margin-left: auto;
        display: flex;
      }

      .entries-input {
        // position: absolute;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        top: 0;
        right: 15px;
        // margin-left: auto;
        // margin-right: 10px;
        // margin-top: -10px;

        p {
          margin: 0px;
          padding: 0px;
        }

        .entries-dropdown {
          width: 70px;
          // padding-top: 15px;
          margin: 0px 5px;
        }
      }

      .search-input {
        width: 50%;
        input[type="text"] {
          display: block;
          padding: 12px 0px;
          background: var(--input-background);
          text-indent: 14px;
          width: 100%;
          margin-top: 10px;
          font-family: inherit;
          font-size: 1em;
          color: #888;
          border: 1px solid transparent;
          border-radius: 5px;
          box-shadow: 1px 1px 2px -1px #ffffff26,
            inset 1px 1px 3px 0px #00000075;
          outline: none;
          -webkit-appearance: none;
          transition: all 0.4s ease, visibility 0s;

          &:focus {
            // width: 188px;
            color: var(--text);
            border: 1px solid #48bb8b;
            text-indent: 32px;
            background: var(--active-background);
          }
        }
      }
    }

    .table-row-wrapper > .table-row-guestlist {
      flex-wrap: wrap;
      border-bottom: 1px solid #494c51;
      .row-1,
      .row-2 {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr;

        .td-element {
          border: 0;
        }
      }
    }
  }

  .table-footer {
    position: relative;
    height: 50px;
    margin: 10px 0 40px;

    .entries-wrapper {
      position: absolute;
      left: 2px;
    }

    .pagination {
      top: -7px;

      position: absolute;
      display: flex;
      flex-wrap: wrap;
      right: 1px;
      user-select: none;

      p {
        width: 8px;
        padding: 7px 14px;
        border: rgba(91, 91, 91, 0.3) solid 1px;
        // color: #4eb95e;

        &:hover {
          cursor: pointer;
          background-color: rgba(91, 91, 91, 0.3);
        }

        &.active {
          background-color: rgba(91, 91, 91, 0.4);
        }

        &.inactive {
          color: #999999;
          pointer-events: none;
        }

        &.from {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &.to {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
</style>
<script>
// import DiyoboCalendar from "@/components/diyoboCalendar.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import debounce from "debounce";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCheck,
  faTimes,
  faFile,
  faReceipt,
  faExternalLinkAlt,
  faTransgender,
  faMars,
  faVenus,
  faGenderless,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { downloadTableToteAsCSV } from "@/helpers/csv";
//import formatMoney from "@/helpers/money";
import {
  emailCheck,
  phoneCheck,
  formatPhoneNumber,
} from "@/helpers/input-formats";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import DiyoboSwitch from "./DiyoboSwitch.vue";
import { convert } from "@/helpers/currencyConversion";

library.add(
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCheck,
  faTimes,
  faFile,
  faReceipt,
  faExternalLinkAlt,
  faMars,
  faVenus,
  faGenderless
);

export default {
  name: "table-tote",
  components: {
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon,
    ProcessingModal,
    // DiyoboCalendar,
    DiyoboSwitch,
  },
  props: {
    guestlist: { type: Boolean, default: false },
    showExpected: { type: Boolean, default: false },
    value: Object,
    dateFrom: String,
    dateTo: String,
    dateSearch: {
      type: Boolean,
      default: () => false,
    },
    eventCurrencyCode: String,
  },
  data() {
    return {
      tableSizes: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      tableSize: { label: "10", value: 10 },
      page: 1,
      search: "",
      sortColumn: null,
      sortDirection: "ascending",
      processing: false,
      start: "",
      end: "",
    };
  },
  computed: {
    currentStoreCurrency() {
      return this.$store.state.currency;
    },
    cadOption() {
      return this.$store.state.cadCurrencyOption;
    },
    usdOption() {
      return this.$store.state.usdCurrencyOption;
    },
    columns() {
      return this.value.columns.filter((c) => !c.hidden);
    },
    pages() {
      if (!this.value.data) return 0;
      return Math.ceil(this.value.data.length / this.tableSize.value);
    },
    datepickerIdSuffix() {
      return this.value.title ? this.value.title.replace(/ /g, "") : "";
    },
    pageButtons() {
      if (this.pages <= 4) {
        return [1, 2, 3, 4].slice(0, this.pages);
      }

      if (this.page > this.pages - 5) {
        return [
          this.pages - 4,
          this.pages - 3,
          this.pages - 2,
          this.pages - 1,
          this.pages,
        ];
      }

      return [this.page, this.page + 1, "...", this.pages - 1, this.pages];
    },
    filteredData() {
      if (this.search == "") {
        if (this.start == "" || this.end == "") {
          return this.value.data;
        } else {
          let d1 = new Date(this.start).getTime();
          let d2 = new Date(this.end).getTime();

          return this.value.data.filter(
            (e) =>
              new Date(e.date).getTime() >= d1 &&
              new Date(e.date).getTime() <= d2
          );
        }
      } else {
        if (this.start == "" || this.end == "") {
          return this.value.data.filter((e) =>
            this.value.searchable.some((s) =>
              e[s]
                .toString()
                .toLowerCase()
                .includes(this.search)
            )
          );
        } else {
          let d1 = new Date(this.start).getTime();
          let d2 = new Date(this.end).getTime();

          return this.value.data.filter(
            (e) =>
              new Date(e.date).getTime() >= d1 &&
              new Date(e.date).getTime() <= d2 &&
              this.value.searchable.some((s) =>
                e[s].toLowerCase().includes(this.search)
              )
          );
        }
      }
    },
    displayData() {
      const offset = (this.page - 1) * this.tableSize.value;
      if (this.sortColumn !== null) {
        const column = this.value.columns.find(
          (c) => c.field === this.sortColumn
        );
        const data = this.filteredData;
        if (column && data.length) {
          const field = column.field;
          if (data[0][field] !== undefined) {
            const type = typeof data[0][field];
            let sortFunc;
            if (type === "boolean") {
              sortFunc = (a, b) =>
                a[field] === b[field] ? 0 : a[field] ? 1 : -1;
            } else if (column.date) {
              sortFunc = (a, b) => {
                const ad = new Date(a[field].replace(/th|nd|st/gi, ""));
                const bd = new Date(b[field].replace(/th|nd|st/gi, ""));

                return ad > bd ? 1 : ad < bd ? -1 : 0;
              };
            } else if (type === "string") {
              sortFunc = (a, b) => {
                const al = a[field].toLowerCase();
                const bl = b[field].toLowerCase();

                return al > bl ? 1 : al < bl ? -1 : 0;
              };
            } else {
              sortFunc = (a, b) =>
                a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0;
            }

            return (this.sortDirection === "ascending"
              ? data.sort(sortFunc)
              : data.sort(sortFunc).reverse()
            ).slice(offset, offset + this.tableSize.value);
          }
        }
      }
      return this.filteredData.slice(offset, offset + this.tableSize.value);
    },
    showingFrom() {
      return (this.page - 1) * this.tableSize.value + 1;
    },
    showingTo() {
      return Math.min(
        this.page * this.tableSize.value,
        this.filteredData.length
      );
    },
  },
  methods: {
    promoDiscountDisplay(row, column) {
      if (row.type === "$ Discount") {
        return "$" + Number(row[column]).toFixed(2);
      } else {
        return row[column];
      }
    },
    bulkDiscountDisplay(row, column) {
      if (row.methodType === "$ Discount") {
        return "$" + Number(row[column]).toFixed(2);
      } else {
        return row[column] + "%";
      }
    },
    changeSwitch(val) {
      this.$emit("change", val);
    },
    getStatus(value) {
      if (value === "accepted" || value === "denied") {
        return true;
      } else {
        return false;
      }
    },
    approveGuest(row, column) {
      this.handleInput(row, column, false, "approve");
    },
    denyGuest(row, column) {
      this.handleInput(row, column, false, "deny");
    },
    minus(type, row, column) {
      this.handleInput(row, column, false, "minus");
    },
    plus(type, row, column) {
      this.handleInput(row, column, false, "plus");
    },
    setStart(val) {
      this.start = val;
    },
    setEnd(val) {
      this.end = val;
    },
    paginate(page, relative) {
      if (page === "...") return;
      if (relative) {
        if (page === -1 && this.page > 1) {
          this.page += page;
        } else if (page === 1 && this.page < this.pages) {
          this.page += page;
          // if (this.page === this.pages) {
          //   this.$emit("fetchData");
          // }
        }
      } else {
        this.page = page;
        // if (this.page === this.pages) {
        //   this.$emit("fetchData");
        // }
      }
    },
    onSearch(search) {
      this.search = search.toLowerCase();
      if (this.page > 1) this.page = 1;
    },
    onChangeTableSize(size) {
      this.tableSize = this.tableSizes.find((s) => s.value === size);
      if (this.page > 1) this.page = 1;
    },
    onToggleSort(column) {
      if (this.sortColumn !== column.field) {
        this.sortDirection = "ascending";
      } else {
        this.sortDirection =
          this.sortDirection === "descending" ? "ascending" : "descending";
      }

      this.sortColumn = column.field;
    },
    handleMoneyKeydown(event, row, column) {
      if (event.key === "Backspace" || event.key === "Delete") {
        this.handleInput(row, column);
        return true;
      }

      if (
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "Tab"
      )
        return true;

      if (isNaN(event.key) && event.key !== ".") {
        event.preventDefault();
        return false;
      }

      let value = event.target.value;
      let decimal = value.indexOf(".");
      if (decimal > -1 && event.key === ".") {
        event.preventDefault();
        return false;
      }

      let cursorBeforeDecimal = event.target.selectionStart - 1 < decimal;
      if (decimal > -1 && !cursorBeforeDecimal && value.length - decimal > 2) {
        event.preventDefault();
        return false;
      }

      this.handleInput(row, column);
    },
    onBlurMoney(row, column) {
      if (!row[column]) {
        row[column] = "0.00";
      } else {
        row[column] = (+row[column]).toFixed(2);
      }
    },
    handleEmailInput(row, column) {
      const errored = !emailCheck(row[column]);
      this.handleInput(row, column, errored);
    },
    handlePhoneInput(row, column) {
      row[column] = formatPhoneNumber(row[column]);

      const errored = !phoneCheck(row[column]);
      this.handleInput(row, column, errored);
    },
    handleInput(row, column, errored = false, type) {
      this.setError(
        row,
        column,
        errored || (!row[column] && typeof row[column] !== "boolean")
      );
      if (type) {
        this.runCallback(row, column, type);
      } else if (!row._errors[column] || column == "delete") {
        this.runCallback(row, column);
      }
    },
    // onYesNo(row, column, errored = false) {
    //   this.setError(
    //     row,
    //     column,
    //     errored || (!row[column] && typeof row[column] !== "boolean")
    //   );
    //   if (!row._errors[column]) {
    //     this.runCallback(row, column);
    //   }
    // },
    runCallback: debounce(function(row, column, type) {
      if (this.value.callbacks[column] && type) {
        this.value.callbacks[column](row, column, type);
      } else if (this.value.callbacks && this.value.callbacks[column]) {
        this.value.callbacks[column](row, column);
      }
      this.$forceUpdate();
    }, 250),
    getIcon(row, column) {
      if (this.value.icons[column]) {
        return this.value.icons[column](row, column, library);
      }

      return "times";
    },
    getUrl(row, column) {
      if (!row.url) {
        return null;
      }

      return `/financials/${row.url}`;
    },
    setError(row, column, value) {
      if (!row._errors) {
        row._errors = {};
      }

      this.$set(row._errors, column, value);
    },
    downloadCSV() {
      const mapper = this.value.csvMapper || ((v) => v);

      let data = mapper({ ...this.value });

      //temp solution for unwanted data columns in csv
      let tempDataColumns = data.columns;
      tempDataColumns = tempDataColumns.filter((x) => x.exempted === undefined);
      data.columns = tempDataColumns;
      // end of solution

      downloadTableToteAsCSV(
        data,
        `${data.title}-${new Date(Date.now()).toLocaleString()}.csv`
      );
    },
    // formatMoney
    formatMoney(price) {
      if (this.cadOption === null && this.usdOption === null) {
        return;
      }
      let currency = null;

      if (this.eventCurrencyCode === "usd") {
        currency = this.usdOption.find(
          (x) => x.label === this.currentStoreCurrency.name
        );
      } else {
        currency = this.cadOption.find(
          (x) => x.label === this.currentStoreCurrency.name
        );
      }

      return convert(currency.value, parseFloat(price), currency.label);
    },
  },
};
</script>

export function downloadTableToteAsCSV(table, filename) {
  const csv = [];
  const rows = table.data;
  const cols = table.columns;

  csv.push(table.columns.map(c => c.title).join(","));
  for (let i = 0; i < rows.length; i++) {
    let row = [];
    for (let j = 0; j < cols.length; j++) {
      if (cols[j].isCurrency) {
        row.push("$" + rows[i][cols[j].field]);
      } else row.push("" + rows[i][cols[j].field]);
    }

    csv.push(row.map(t => t.replace(/,/g, "")).join(","));
  }

  if (table.totals) {
    const totals = ["Totals"];
    for (let i = 1; i < cols.length; i++) {
      let total = table.totals[cols[i].field];
      if (!total) totals.push(" ");
      else if (cols[i].isCurrency) {
        totals.push("$" + total(rows));
      } else totals.push("" + total(rows));
    }

    csv.push(totals.map(t => t.replace(/,/g, "")).join(","));
  }

  downloadCSV(csv.join("\n"), filename);
}

export function downloadCSV(csv, filename) {
  const csvFile = new Blob([csv], { type: "text/csv" });
  const download = document.createElement("a");

  download.download = filename;
  download.href = window.URL.createObjectURL(csvFile);
  download.style.display = "none";
  document.body.appendChild(download);
  download.click();
}

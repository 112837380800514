<template>
  <div class="switch-wrapper">
    <label for="checkbox" class="label" v-if="label">{{ label }}</label>
    <label class="switch">
      <input type="checkbox" name="checkbox" v-model="check" />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: Boolean,
  },
  data() {
    return {
      check: this.value,
    };
  },
  watch: {
    check(newValue) {
      this.$emit("change", newValue);
    },
  },
};
</script>

<style lang="less" scoped>
.switch-wrapper {
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  margin-right: 12px;
}
.label {
  font-size: 14px;
  margin-right: 8px;
}
.switch {
  position: relative;
  display: block;
  width: 50px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 0px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #44b678;
}

input:focus + .slider {
  box-shadow: 0 0 1px #44b678;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

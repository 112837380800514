import dateformat from "dateformat";

export function formatPhoneNumber(phone, edit) {
  if (edit == "delete") {
    return phone;
  }

  phone = phone.replace(/\D/g, "");
  phone = phone.substring(0, 10);

  let length = phone.length;

  if (length < 10) {
    phone = phone;
  } else {
    if (length == 0) {
      phone = "";
    } else if (length < 4) {
      phone = "(" + phone;
    } else if (length < 7) {
      phone = "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6);
    } else {
      phone =
        "(" +
        phone.substring(0, 3) +
        ") " +
        phone.substring(3, 6) +
        " - " +
        phone.substring(6, 10);
    }
  }
  return phone;
}

export function postalFormat(post, country) {
  var correctPost;
  if (country == "Canada") {
    post = post.replace(/\s/g, "");
    if (post.length < 4) {
      correctPost = post.substring(0, 3);
    } else {
      correctPost = post.substring(0, 3) + " " + post.substring(3, 6);
    }

    return correctPost;
  } else if (country == "United States of America") {
    post = post.replace(/\D/g, "");
    correctPost = post.substring(0, 5);
    return correctPost;
  } else {
    return post;
  }
}

export function creditFormat(card) {
  if (card.length >= 19) {
    let format = card.slice(0, 19);
    return format;
  }

  switch (card.length) {
    case 4:
      card += "-";
      break;
    case 9:
      card += "-";
      break;
    case 14:
      card += "-";
      break;
    default:
      break;
  }

  return card;
}

export function emailCheck(email) {
  var pattern = /\S+@\S+\.\S+/;
  if (pattern.test(email)) {
    return true;
  } else {
    return false;
  }
}

export function dateFormat(date) {
  var cleaned = ("" + date).replace(/\D/g, "");

  if (cleaned.length < 8) {
    return date;
  } else {
    if (cleaned.length <= 2) {
      return cleaned;
    } else if (cleaned.length <= 4) {
      let newDate = cleaned.slice(0, 4);

      let firstBlock = newDate.substr(0, 2);
      let secondBlock = newDate.substr(2, 2);

      return firstBlock + "/" + secondBlock;
    } else {
      let newDate = cleaned.slice(0, 10);

      let firstBlock = newDate.substr(0, 2);
      let secondBlock = newDate.substr(2, 2);
      let thirdBlock = newDate.substr(4, 4);

      return firstBlock + "/" + secondBlock + "/" + thirdBlock;
    }
  }
}

export function websiteCheck(site) {
  if (site == "") {
    return true;
  }

  var pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  if (pattern.test(site)) {
    return true;
  } else {
    return false;
  }
}

export function youtubeCheck(site) {
  // if(site == ''){
  //     return true;
  // }

  // var pattern = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;

  var pattern = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)\&?/;
  let patternVimeo = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

  if (pattern.test(site) || patternVimeo.test(site)) {
    return true;
  } else {
    if (site == "") {
      return true;
    } else {
      return false;
    }
  }
}

export function videoSiteCheck(site) {
  let youtubePattern = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)\&?/;
  let vimeoPattern = /(?:https?:\/\/)?(?:www\.)?vimeo?(?:\.com)*\/(?:[\w|\d])/;
  //?vimeo\.(?:\.com)
  if (vimeoPattern.test(site) || youtubePattern.test(site)) {
    return true;
  } else {
    return false;
  }
}

export function phoneCheck(phone) {
  return /^(\+\d{1,2}\s)?\(?\d{3}\)?\s*[\s.-]\s*\d{3}\s*[\s.-]\s*\d{4}$/.test(
    phone
  );
}

export function inputPhoneNumberOnly(element, callBackFunc) {
  let phoneOutput = "";
  element.addEventListener("keydown", function(e) {
    // console.log(e)
    // console.log(event.keyCode)
    if (
      e.keyCode != 8 &&
      e.keyCode != 46 &&
      e.keyCode != 9 &&
      e.keyCode != 39 &&
      e.keyCode != 37
    ) {
      if (!parseInt(e.key)) {
        if (e.key != 0) {
          e.target.setAttribute("readonly", true);
        }
      }
    }
  });
  element.addEventListener("keyup", function(event) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      phoneOutput = formatPhoneNumber(event.target.value, "delete");
    } else {
      let phone = event.target.value.replace(/\D/g, "");
      if (phone.length < 10) {
        phoneOutput = formatPhoneNumber(event.target.value);
      } else {
        phoneOutput = formatPhoneNumber(event.target.value);
      }
    }
    if (event.target.hasAttribute("readonly"))
      event.target.removeAttribute("readonly");
    callBackFunc(phoneOutput);
  });
}

export function formatDate(dateStr) {
  return dateformat(parseInt(dateStr), "ddd, mmm dS, yyyy");
}
export function formatTime(dateStr) {
  return dateformat(parseInt(dateStr), "hh:MM TT");
}

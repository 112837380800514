<template>
  <div class="breadcrumbs">
    <router-link to="/dashboard" class="home" v-if="isPromoter">
      Home
    </router-link>
    <router-link to="/my-tickets" class="home" v-else>
      Home
    </router-link>
    <!-- <div class="home">
      Home
    </div> -->
    <div v-for="([crumb, link], i) in crumbs" class="previous" :key="i">
      <router-link v-if="link" class="crumb" :to="link">
        <font-awesome-icon icon="chevron-circle-left" />

        {{ crumb }}</router-link
      >
      <div v-else class="current">
        <font-awesome-icon icon="chevron-circle-right" /> {{ crumb }}
      </div>
    </div>

    <!-- <div class="previous">
      <font-awesome-icon icon="chevron-circle-left" />

      Previous Page
    </div> -->
    <!-- <div class="current">
      <font-awesome-icon icon="chevron-circle-right" />

      Current Page
    </div> -->
    <!-- <div v-for="([crumb, link], i) in crumbs" class="crumb-wrapper" :key="i">
      <router-link v-if="i === 0" to="/" class="crumb">
        Home
      </router-link>
      <router-link v-if="link" class="crumb" :to="link">{{
        crumb
      }}</router-link>
      <div v-else class="crumb">{{ crumb }}</div>
    </div> -->
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faChevronCircleLeft);
library.add(faChevronCircleRight);

export default {
  name: "breadcrumbs",
  components: {
    FontAwesomeIcon,
  },
  props: {
    crumbs: Array,
    isPromoter: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="less" scoped>
.breadcrumbs {
  display: flex;
  width: 100%;
  margin: 10px 0;
  font-size: 16px;
  column-gap: 10px;
  .crumb-wrapper {
    margin-right: -2px;

    .crumb {
      display: inline-block;
      color: var(--text);
      background: var(--breadcrumb);
      vertical-align: top;
      padding: 5px 10px;
    }

    a {
      color: @colors[breadcrumbs] !important;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 13px solid var(--breadcrumb);
      border-bottom: 14px solid var(--breadcrumb);
      border-left: 5px solid transparent;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-left: 5px solid var(--breadcrumb);
    }
  }

  .crumb,
  .previous,
  .current {
    cursor: pointer;
  }

  .current {
    cursor: pointer;

    svg {
      color: var(--primary);
    }
  }
  :first-child {
    &:before {
      display: none;
    }
  }

  // :last-child {
  //   .crumb {
  //     background-image: linear-gradient(160deg, #4eb95e, #4a639e);
  //   }

  //   &:after {
  //     border-image-source: linear-gradient(160deg, #4eb95e, #4a639e);
  //   }
  // }
}
</style>
